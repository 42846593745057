import axios from 'axios'
import { Toast } from 'vant'
const ajax = axios.create()
// 拦截器
let toast = ''
ajax.interceptors.request.use(config => {
  toast = Toast.loading({
    duration: 0, // 持续展示 toast
    forbidClick: true, // 禁用背景点击
    loadingType: 'spinner',
    message: '加载中...'
  })
  return config
}, err => {
  return Promise.reject(err)
})
const config = window.sessionStorage.getItem("Token");
//添加请求头
ajax.interceptors.response.use(resp => {
  toast.clear()
  return resp.data
}, err => {
  toast.clear()
  return Promise.reject(err)
})
//登陆
export const getToLogin = (data) => {
  return ajax.post('https://www.papertool.cn/api/v1/authorizations', data)
}
//检测系统的分类
export const getjcList = () => {
  return ajax.get('https://www.papertool.cn/api/v1/categories')
}


//查看报告列表
export const getReport = (data) => {
  return ajax.get('https://www.papertool.cn/api/v1/orders', {
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("Token")
    }
  })
}
//注册
export const getToRegister = (data) => {
  return ajax.post('https://www.papertool.cn/api/v1/users', data)
}
//验证码
export const getCode = (data) => {
  return ajax.post('https://www.papertool.cn/api/v1/verificationCodes', data)
}
//发送邮件
export const getToEmail = (data, params) => {
  return ajax.post(`https://www.papertool.cn/api/v1/orders/${data}/mail_report`, params, {
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("Token")
    }
  })
}
//内置支付
export const getPayCode = (data, params) => {
  return ajax.get(`https://www.papertool.cn/payments/${data}/wechat_mp?code=${params}`, {
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("Token")
    }
  })
}
//上传文件
export const getFileId = (data) => {
  return ajax.post('https://www.papertool.cn/api/v1/files', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: "Bearer " + window.sessionStorage.getItem("Token")
    }
  })
}
//提交上传文件
export const getToUpload = (data) => {
  return ajax.post('https://www.papertool.cn/api/v1/orders', data, {
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("Token")
    }
  })
}
//粘贴上传文件
export const getTopaste = (data) => {
  return ajax.post('https://www.papertool.cn/api/v1/orders', data, {
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("Token")
    }
  })
}
//分享图片
export const getQrCode = (data) => {
  return ajax.get(`https://www.papertool.cn/api/v1/qcrode/generate_img?title=${data.title}&writer=${data.writer}&category_name=${data.bbname}&created_at=${data.tjtime}&rate=${data.rate}`, {
    headers: {
      Authorization: "Bearer " + window.sessionStorage.getItem("Token")
    }
  })
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
//默认样式reset
import '@/assets/css/reset.css'
import '@/assets/css/icon.css'
import rem from '@/rem'
import * as ajax from '@/request'
import clipboard from 'clipboard';
// vant
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)
Vue.prototype.$http = ajax;
Vue.prototype.globaldata = {};
Vue.prototype.clipboard = clipboard;
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.meta.isAuthRequired === true) {
    if (window.sessionStorage.getItem("Token")) {
      next()
    } else {
      next({
        name: 'Login',
        params: {
          from: to.path
        }
      })
    }
  }
  next()
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="app">
    <div class="app-mian" ref="apptop">
      <router-view></router-view>
    </div>
    <router-view name="footer" class="app-footer"></router-view>
  </div>
</template>
<script>
export default {
  watch: {
    $route: function(to, from) {
      this.$refs.apptop.scrollTop = 0;
    }
  },
  mounted() {
    window.sessionStorage.removeItem("show");
  }
};
</script>
<style>
.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
}
.app-mian {
  flex: 1 !important;
  height: 100%;
  overflow-x: auto;
}
.app-footer {
  height: 1rem;
  background-color: #f7f7f7;
  width: 100%;
}
</style>

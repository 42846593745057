const Home = () => import('@/views/Home')
const Jcsystem = () => import('@/views/jcsystem')
const Report = () => import('@/views/report')
const Service = () => import('@/views/service')
const Lwfooter = () => import('@/components/lwfooter')
const Ccpage = () => import('@/views/ccpage')
const Pay = () => import('@/views/pay')
const Login = () => import('@/components/Login')
const Register = () => import('@/components/Register')
const About = () => import('@/views/about')
const Payment = () => import('@/views/Payment')
const Result = () => import('@/views/result')
const Introduce = () => import('@/views/introduce')
const Matter = () => import('@/views/matter')
const SurePay = () => import('@/views/surepay')
const Qrimg = () => import('@/views/qrimg')
const Jcfenlei = () => import('@/views/jcfenlei')

export default [
  {
    path: '/',
    redirect: '/home',
    meta: {}
  },
  {
    path: '/jcfenlei',
    name: 'jcfenlei',
    component: Jcfenlei,
    meta: {
      isTabbar: false
    }
  },
  {
    path: '/home',
    name: 'home',
    components: { default: Home, footer: Lwfooter },
    meta: {
      isTabbar: true,
      title: '首页',
      id: 'home',
      icon: '&#xe640;',
      activeIcon: '&#xe63a;'
    }
  },
  {
    path: '/jcsystem',
    name: 'jcsystem',
    components: { default: Jcsystem, footer: Lwfooter },
    meta: {
      isTabbar: true,
      title: '检测系统',
      id: 'jcsystem',
      icon: '&#xe607;',
      activeIcon: '&#xe63c;'
    }
  },
  {
    path: '/report',
    name: 'report',
    components: { default: Report, footer: Lwfooter },
    meta: {
      isTabbar: true,
      title: '查看报告',
      id: 'report',
      icon: '&#xe63f;',
      activeIcon: '&#xe605;',
    }
  },
  {
    path: '/service',
    name: 'service',
    components: { default: Service, footer: Lwfooter },
    meta: {
      isTabbar: true,
      title: '个人中心',
      id: 'service',
      icon: '&#xe634;',
      activeIcon: '&#xe641;'
    }
  },
  {
    path: '/ccpage',
    name: 'ccpage',
    components:

      { default: Ccpage, footer: Lwfooter },

    meta: {
      isTabbar: false,
      isAuthRequired: true
    }
  },
  {
    path: '/pay',
    name: 'pay',
    component: Pay,
    meta: {
      isTabbar: false
    }
  },
  {
    path: '/qrimg',
    name: 'qrimg',
    component: Qrimg,
    meta: {
      isTabbar: false
    }
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
    meta: {
      isTabbar: false
    }

  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: {
      isTabbar: false
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      isTabbar: false
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      isTabbar: false
    }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      isTabbar: false
    }
  },
  {
    path: "/introduce",
    name: 'introduce',
    component: Introduce,
    meta: {
      isTabbar: false
    }
  },
  {
    path: "/matter",
    name: 'matter',
    component: Matter,
    meta: {
      isTabbar: false
    }
  },
  {
    path: "/surepay",
    name: 'surepay',
    component: SurePay,
    meta: {
      isTabbar: false
    }
  }
]
